import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { SharedModule } from './shared/shared.module';
// import { FilterValuesService } from './shared/services/filter-values.service';
// import { HttpService } from './shared/services/http.service';
import { HttpClientModule } from '@angular/common/http';
// import { SharedService } from './shared/services/shared.service';
import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpInterceptorService } from './shared/services/http-interceptor.service';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    // SharedModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    // NgSelectModule,    
  ],
  providers: [DatePipe, Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
