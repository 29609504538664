export const monthToInt = {
    "January": 1,
    "February": 2,
    "March": 3,
    "April": 4,
    "May": 5,
    "June": 6,
    "July": 7,
    "August": 8,
    "September": 9,
    "October": 10,
    "November": 11,
    "December": 12
}
export const intToMonth = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December"
}

export const intToMonthAbbr = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sept",
    9: "Oct",
    10: "Nov",
    11: "Dec"
}

export const YESorNO = {
    'YES':'YES',
    'NO':'NO'
}

export const retiredFlagDropDown = [
    {retired_flag_name : "(All)"  , retired_flag_value : "(All)"},
    {retired_flag_name : "Presumed Retired" , retired_flag_value : "Presumed Retired"},
    {retired_flag_name : "Retired-No License" , retired_flag_value : "Retired-No License"},
    {retired_flag_name : "No"  , retired_flag_value : "NO"}
]
// error messages
export const SOMETHING_WENT_WRONG = 'Something went wrong. Please try again later.';
export const No_DATA_FOUND = 'No records found for your filter request';

// Api responses
export const API_SUCCESS_MESSAGE = '200';
export const API_FAILURE_MESSAGE = '400';

// Roles
export const SO = 'SO';
export const HQ = 'HQ';
export const TM = 'TM';
export const DM = 'DM';
export const RM = 'RM';

//titles

export const shipToSearchTitle = "AEC | ShipTo Search Page"
export const shipToDetailsTitle = "AEC | ShipTo Details Page"


