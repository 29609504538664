import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BasicDetailsService } from './basic-details.service';
import { API_SUCCESS_MESSAGE } from '../utils/constants';
import { LocalStorageService } from '../utils/local-storage.service';
import { environment } from 'src/environments/environment';
import { LIVE_SERVICE_NAMES, LOCAL_SERVICE_NAMES } from '../utils/api-endpoints';
import { map } from 'rxjs/operators';
import { HttpService } from './http.service';
import * as variables from '../utils/api-endpoints';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private localStorageService: LocalStorageService,
    private http: HttpService
  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let payload = {};
    return this.http.postMethod(environment.serverUrl + LIVE_SERVICE_NAMES.getUserDetails, payload).pipe(map(res => {
      //return this.http.getMethod(environment.serverUrl + variables.LOCAL_SERVICE_NAMES.getUserDetails).pipe(map(res => {
      if (res.status === API_SUCCESS_MESSAGE) {
        this.localStorageService.setStorage('userDetails', JSON.stringify(res.result.userDetails))
        return true;
      } else {
        const currentLocation = location.href;
        window.location.href = environment.idmLogInUrl
        this.localStorageService.setStorage('userDetails', null);
        return false;
      }
    }, err => {
      const currentLocation = location.href;
      window.location.href = environment.idmLogInUrl
      this.localStorageService.setStorage('userDetails', null);
      return false;
    }))
  }
}
